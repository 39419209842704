<template>
  <form
    ref="examus"
    :action="action"
    method="post"
  >
    <input
      type="hidden"
      name="token"
      :value="token"
    >
  </form>
</template>

<script>

export default {
  name: 'ExamusProctoring',

  props: {
    url: {
      type: String,
      default: ''
    },

    token: {
      type: String,
      default: ''
    },
  },

  computed: {
    action () {
      return this.url + '/start/'
    }
  },

  mounted () {
    if (window.self === window.top) {
      this.$refs.examus.submit()
    }
  },
}
</script>
